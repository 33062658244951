.blur-effect {
    filter: blur(3px);
    /* Adjust the pixel value to control the amount of blur */
}

.logo-size {
    height: 25px;
}

.pt-main {
    padding-top: 4.5em;
}

#topbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    /* Adjust the z-index as needed */
}

.px-custom-conversation {
    padding-right: 30%;
    padding-left: 30%;
}

.btn-add {
    position: fixed;
    bottom: 20px;
    right: 0;
    width: 100%;
    z-index: 3;
    /* Adjust the z-index as needed */
}

.bg-custom-1 {
    border-radius: 0.7rem;
    background: linear-gradient(40deg, #0d4753, #13a06c, #13a06c, #0d4753);
    background-size: 200% 200%;
    transition: background-position 0.5s ease, transform 0.5s ease;
    /* Add transform to the transition */
    background-position: 0% 50%;
}

.bg-custom-2 {
    border-radius: 0.7rem;
    background: linear-gradient(40deg, #3c445e, #11989f, #11989f, #3c445e);
    background-size: 200% 200%;
    transition: background-position 0.5s ease, transform 0.5s ease;
    /* Add transform to the transition */
    background-position: 0% 50%;
}

.bg-custom-3 {
    border-radius: 0.7rem;
    background: linear-gradient(40deg, #181926, #3c445e, #3c445e, #181926);
    background-size: 200% 200%;
    transition: background-position 0.5s ease, transform 0.5s ease;
    /* Add transform to the transition */
    background-position: 0% 50%;
}

.bg-custom-4 {
    border-radius: 0.7rem;
    background: linear-gradient(40deg, #14184c, #3c445e, #3c445e, #14184c);
    background-size: 200% 200%;
    transition: background-position 0.5s ease, transform 0.5s ease;
    /* Add transform to the transition */
    background-position: 0% 50%;
}

.bg-custom-5 {
    border-radius: 0.7rem;
    background: linear-gradient(40deg, #4a4a4a, #b8b8b8, #ffffff, #515151);
    background-size: 200% 200%;
    transition: background-position 0.5s ease, transform 0.5s ease;
    /* Add transform to the transition */
    background-position: 0% 50%;
}

.bg-custom-conversation {
    border-radius: 0.7rem;
    background: linear-gradient(40deg, #d0d0d0, #d0d0d0, #d0d0d0, #9d9d9d);
    background-size: 200% 200%;
    transition: background-position 0.5s ease, transform 0.5s ease;
    /* Add transform to the transition */
    background-position: 0% 50%;
}

.bg-custom-1:hover,
.bg-custom-2:hover,
.bg-custom-4:hover,
.bg-custom-3:hover,
.bg-custom-5:hover,
.bg-custom-conversation:hover {
    cursor: pointer;
    background-position: 100% 50%;
    transform: scale(1.02);
    /* Increase the size a bit */
}

.clickable {
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    /* Adjust the duration and timing function as needed */
}

.clickable:hover {
    transform: scale(1.02);
    background-color: #9698a010;
}

.modal-content {
    border: solid lightgrey 0px !important;
    border-radius: 1rem !important;
    background: rgba(255, 255, 255, 0.9) !important;
    /* Fondo blanco semitransparente */
    backdrop-filter: blur(4px) !important;
    /* Efecto de desenfoque */
    -webkit-backdrop-filter: blur(4px) !important;
}

/* Style the icons - hidden by default, with transition for smooth effect */
.cita-option i {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.5s, opacity 0.2s linear;
}

/* Show icons when hovering over the specific parent row */
.row:hover>.cita-option i {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.2s linear;
}

.w-20 {
    width: 20%;
}

.me-date {
    margin-right: 70px;
}

.me-date2 {
    margin-left: 70px;
}

.user_image {
    margin-right: 15px;
    bottom: 10px;
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.renata_image {
    margin-left: 15px;
    bottom: 10px;
    width: 50px;
    height: 50px;
    object-fit: cover;
}

.message-item-1 {
    border-radius: 0.7rem;
    transition: background-color 0.1s ease-in-out, transform 0.2s ease-in-out;
    /* Adjust the properties and timing function as needed */
    background-color: #f2f2f2;
    max-width: 60%;
}


.message-item-2 {
    border-radius: 0.7rem;
    transition: background-color 0.1s ease-in-out, transform 0.2s ease-in-out;
    /* Adjust the properties and timing function as needed */
    background-color: #54afece9;
    max-width: 60%;
}

.conversation-item {
    border-radius: 0.7rem;
    border-bottom-left-radius: 1px !important;
    border-top-left-radius: 1px !important;
    transition: background-color 0.1s ease-in-out, transform 0.2s ease-in-out;
    /* Adjust the properties and timing function as needed */
}

.conversation-item:hover {
    background-color: #f2f2f2;
    cursor: pointer;
}

.conversation-item.selected {
    background-color: #f2f2f2;
    border-color: #181926 !important;
}

.conversation-box {
    max-height: 410px;
    /* Altura máxima de la caja de conversación */
    overflow-y: auto;
    /* Scroll vertical */
    overflow-x: hidden;
}

/* Ocultar el ícono por defecto, pero mantener su espacio */
.bi-circle.hide {
    visibility: hidden;
}

/* Mostrar el ícono cuando el elemento de la conversación esté en hover */
.conversation-item:hover .bi-circle {
    visibility: visible;
}

/* Mantener el ícono visible si el elemento tiene la clase `selected` */
.conversation-item.selected .bi-circle {
    visibility: visible;
}

.icon-upload {
    font-size: xx-large;
}


@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.large-checkbox {
    width: 30px;
    height: 30px;
    transform: scale(1.5);
    cursor: pointer;
}

.form-control-custom {
    border: 0 !important;
}

.form-control-custom:hover,
.form-control-custom:focus {
    border: 0 !important;
    outline: none !important;
    /* Remove outline */
    box-shadow: none !important;
    /* Remove Bootstrap's glow effect */
}

.accordion-collapse {
    transition: height 0.35s ease;
    overflow: hidden;
}

.btn-outline-dark:hover {
    background-color: rgba(0, 0, 0, 0.131) !important;
    color: #181926 !important;
}

.border-radius-custom-1 {
    border-bottom-left-radius: 40px !important;
    border-top-left-radius: 40px !important;
}

.border-radius-custom-2 {
    border-bottom-right-radius: 40px !important;
    border-top-right-radius: 40px !important;
}

.w-150-px {
    width: 110px;
    text-align: center;
}

.custom-height-categoria {
    height: 130px;
}

.modal-dates {
    width: 100%;
    max-width: 100%;
  }
