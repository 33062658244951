.p-login {
    padding-top: 6em;
}

.centered-placeholder::placeholder {
    text-align: center;
}

.centered-input-container {
    text-align: center;
    display: flex;
    justify-content: center;
}

.loading {
    max-height: 18px;
}